import React from 'react'

import Layout from '../components/Layout';
import { MyLocationEnum } from '../components/Layout/utils/enum'
import VideoRoom from '../components/ConferenceRoom/components/VideoRoom';
import SEO, { MetaOG } from "../components/Seo"
import { useStaticQuery, graphql } from 'gatsby';

interface Props {
  location: Location
}
export default ({ location }: Props) => {
  const seo_og: MetaOG = {
    title: "Bein útsending",
    description: "Bein útsending frá bjórjólum Advania 2020",
  }

  const data = useStaticQuery(
    graphql`
      query {
        Background: file(relativePath: { eq: "shutterstock_1323484583_2.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Layout location={location} myLocation={MyLocationEnum.Live}>
      <SEO
        title="Bein útsending"
        description="Bein útsending frá bjórjólum Advania 2020"
        og={seo_og}
      />
      <VideoRoom 
        videoLink="https://player.vimeo.com/video/489894960"
        slidoLink="https://app.sli.do/event/oibp40y5" 
        backgroundImage={data.Background.childImageSharp.fluid} 
      />
    </Layout>
  )
}
